import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getDecryptedCookie } from "../utils/Utils";
import { GetSingleUserTicket, ReplyUserTicket } from "../api/TicketApi";
import { Toaster, toast } from "react-hot-toast";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

export const TicketDetails = () => {
  const { ticketId } = useParams();
  const [ticketDetails, setTicketDetails] = useState(null);
  const userData = getDecryptedCookie("UserData");
  const token = userData?.token;

  const FetchSingleTicketDetails = async () => {
    try {
      const res = await GetSingleUserTicket(ticketId, token);
      console.log(res, "GetSingleUserTicketGetSingleUserTicket");
      if (res?.status === "success") {
        setTicketDetails(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    FetchSingleTicketDetails();
  }, []); // Add dependencies

  //   Reply Ticket

  const validationSchema = Yup.object({
    message: Yup.string().required("message is required"),
  });

  const initialValues = {
    message: "",
  };

  const handleTicketReply = async (values, actions) => {
    try {
      const userData = getDecryptedCookie("UserData"); // Get the decrypted user data from cookies
      //   const token = userData?.token;
      const token =
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY3OWM1ZWQ2NDBmODYzOTlhYTg1NTRjYSIsImlhdCI6MTczODM5NDE2N30.bPw2sSQ3wl8DYAW3RH9_zRXKU9zApszB0XAzId98XjA"; // Extract token number from userData

      const formData = {
        message: values.message,
      };

      const res = await ReplyUserTicket(ticketId, token, formData);

      if (res?.status === "success") {
        toast.success(res?.message, { autoClose: 3000 });
        actions.resetForm();
        FetchSingleTicketDetails();
      } else if (
        res?.message === "Replies are allowed only if the ticket is open"
      ) {
        toast.error(res?.message, { autoClose: 3000 });
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again.");
    }

    actions.setSubmitting(false); // Enable the submit button after process
  };

  return (
    <div>
      <Toaster />
      <h2 className="text-xl font-semibold mb-4">Ticket Details</h2>

      <section className="raise_ticket_history_section">
        {/* container start */}
        <div className="container">
          {/* row start */}
          <div className="row justify-content-center">
            {/* col start */}
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              {/* raise ticket history main div start */}
              <div className="raise_ticket_history_main_div">
                {/* raise ticket history username and email div start */}

                <div className="raise_ticket_history_details_div">
                  <h1>
                    <strong className="text-danger">Subject: </strong>
                    {ticketDetails?.subject}
                  </h1>
                  <p className="mb-3">
                    <strong className="text-danger">Description: </strong>
                    {ticketDetails?.description}
                  </p>

                  <div className="raise_ticket_messages">
                    {ticketDetails?.replies?.map((reply, index) =>
                      reply.user === ticketDetails?.user ? (
                        // User messages (Red background)
                        <div
                          className="raise_ticket_history_details_div mb-1"
                          style={{
                            backgroundColor: "lightgray",
                          }}
                          key={index}
                        >
                          <p>{reply.message}</p>

                          {/* raise ticket history date and time div start */}
                          <div className="raise_ticket_history_date_time_div">
                            <p>
                              <strong>
                                <div className="d-flex">
                                  <div>
                                    {new Date(
                                      reply?.createdAt
                                    )?.toLocaleDateString("en-GB", {
                                      day: "2-digit",
                                      month: "2-digit",
                                      year: "numeric",
                                    })}
                                  </div>
                                  <div className="ps-1">
                                    {new Date(
                                      reply?.createdAt
                                    )?.toLocaleTimeString("en-US", {
                                      hour: "numeric",
                                      minute: "2-digit",
                                      hour12: true,
                                    })}
                                  </div>
                                </div>
                              </strong>
                            </p>
                          </div>
                          {/* raise ticket history date and time div end */}
                        </div>
                      ) : (
                        // Admin messages (Yellow background)
                        <div
                          className="raise_history_answered_details_div mb-1"
                          key={index}
                          style={{
                            backgroundColor: "lightgreen",
                          }}
                        >
                          <p>{reply.message}</p>

                          {/* raise ticket history date and time div start */}
                          <div className="raise_ticket_history_answered_date_time_div">
                            <p>
                              <strong>
                                {new Date(reply?.createdAt)?.toLocaleDateString(
                                  "en-GB",
                                  {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                  }
                                )}{" "}
                                {new Date(reply?.createdAt)?.toLocaleTimeString(
                                  "en-US",
                                  {
                                    hour: "numeric",
                                    minute: "2-digit",
                                    hour12: true,
                                  }
                                )}
                              </strong>
                            </p>
                          </div>
                          {/* raise ticket history date and time div end */}
                        </div>
                      )
                    )}
                  </div>
                </div>

                <div className="">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleTicketReply}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <div className="row p-0 m-0">
                          {/* Description Field */}
                          <div className="col-md-10  p-0 m-0 input_box_div">
                            <Field
                              name="message"
                              placeholder="Enter your Reply"
                              className="form-control"
                              rows="7"
                            />
                            <ErrorMessage
                              name="message"
                              component="div"
                              className="text-danger"
                            />
                          </div>

                          <div className="col-md-2 input_box_btn_div">
                            <button
                              type="submit"
                              className="btn btn-md bg-info"
                              disabled={isSubmitting}
                            >
                              Send
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
              {/* raise ticket history main div end */}
            </div>
            {/* col end */}
          </div>
          {/* row end */}
        </div>
        {/* container end */}
      </section>
    </div>
  );
};
