import { Base_Url } from "../constant/Url";

export const GetAllUserTicket = async (token) => {
  try {
    const res = await fetch(`${Base_Url}/ticket/my-tickets`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`, // Include token in the Authorization header
      },
    });

    // Parse the response as JSON
    const responseData = await res.json();

    // Check if the request was successful
    if (!res.ok) {
      throw new Error(responseData.message || "Failed to add product to cart");
    }

    // Return the response data (e.g., the updated cart or success message)
    return responseData;
  } catch (error) {
    // Handle errors more explicitly
    console.error("An error occurred while adding to cart:", error.message);
    return { success: false, message: error.message }; // Return error message to handle in the calling function
  }
};

export const GetSingleUserTicket = async (ticketId, token) => {
  try {
    const res = await fetch(`${Base_Url}/ticket/${ticketId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`, // Include token in the Authorization header
      },
    });

    // Parse the response as JSON
    const responseData = await res.json();

    // Check if the request was successful
    if (!res.ok) {
      throw new Error(responseData.message || "Failed to add product to cart");
    }

    // Return the response data (e.g., the updated cart or success message)
    return responseData;
  } catch (error) {
    // Handle errors more explicitly
    console.error("An error occurred while adding to cart:", error.message);
    return { success: false, message: error.message }; // Return error message to handle in the calling function
  }
};

export const ReplyUserTicket = async (ticketId, token, data) => {
  try {
    console.log(ticketId, token, data);

    const res = await fetch(`${Base_Url}/ticket/${ticketId}/reply`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Include token
      },
      body: JSON.stringify(data), // Ensure this has `message`
    });

    // Parse the response as JSON
    const responseData = await res.json();

    // Check if the request was successful
    if (!res.ok) {
      throw new Error(responseData.message || "Failed to add product to cart");
    }

    // Return the response data (e.g., the updated cart or success message)
    return responseData;
  } catch (error) {
    // Handle errors more explicitly
    console.error("An error occurred while adding to cart:", error.message);
    return { success: false, message: error.message }; // Return error message to handle in the calling function
  }
};

export const DeleteUserTicket = async (ticketId, token) => {
  try {
    const res = await fetch(`${Base_Url}/ticket/${ticketId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`, // Include token
      },
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const ReOpenUserTicket = async (ticketId, token) => {
  try {
    const res = await fetch(`${Base_Url}/ticket/${ticketId}/status`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`, // Include token
      },
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const UpdateStatusUserTicket = async (ticketId, token, data) => {
  try {
    const res = await fetch(`${Base_Url}/ticket/${ticketId}/status`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Include token
      },
      body: JSON.stringify(data), // Ensure this has `message`
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};
