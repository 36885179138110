import axios from "axios";

import { Base_Url } from "../constant/Url";

export const createSocialMedia = async (formData) => {
  try {
    const response = await axios.post(
      `${Base_Url}/socialmedia/createsocialmedia`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error creating our Social Media:", error);
    throw error;
  }
};

export const getallSocialMedia = async () => {
  try {
    const res = await fetch(`${Base_Url}/socialmedia/getallsocialmedia`, {
      method: "Get",
      headers: {
        "Content-type": "application/json",
      },
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const DeleteSingleSocialMediaApi = async (id) => {
  try {
    const res = await fetch(
      `${Base_Url}/socialmedia/deletesinglesocialmedia/${id}`,
      {
        method: "Delete",
        headers: {
          "Content-type": "application/json",
        },
      }
    );
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const UpdateSingleSocialMedia = async (updateSocialMedia_id,formData) => {
  try {
    const response = await axios.put(`${Base_Url}/socialmedia/updatesinglesocialmedia/${updateSocialMedia_id}`, formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response?.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
