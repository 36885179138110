import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  GetProductReviewsAPI,
  getSingleProducts,
  updateProductReviewsStatusAPI,
  UpdateSingleProducts,
} from "../api/ProductsApi";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";

import { Toaster, toast } from "react-hot-toast";
import {
  FetchAllSubCategoriesWithCategories,
  getallCategory,
} from "../api/CategoryApi";
import { getAllOurBrands } from "../api/OurBrandsApi";
import { getDecryptedCookie } from "../utils/Utils";
import { Table } from "react-bootstrap";
import axios from "axios";

export const UpdateProduct = () => {
  const { productId } = useParams();

  const [Reviews, setReviews] = useState([]);

  const [singleProductData, setSingleProductData] = useState(null);
  const [categoryListData, setcategoryListData] = useState([]);
  const [SelectedCategory, setSelectedCategory] = useState(
    singleProductData?.CategoryNameSlug
  );
  const [SubCategoryListData, setSubCategoryListData] = useState([]);
  const [AllShopsData, setAllShopsData] = useState([]);

  const getSingleProductDetails = async () => {
    try {
      const response = await getSingleProducts(productId);
      console.log(response);
      if (response?.status == "success") {
        setSingleProductData(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSingleProductReviewsDetails = async () => {
    const AdminData = getDecryptedCookie("AdminData");
    const token = AdminData?.token;

    try {
      const response = await GetProductReviewsAPI(token);

      if (response?.status == "success") {
        setReviews(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSingleProductReviewsDetails();
  }, []);

  const FetchAllCategoryList = async () => {
    try {
      const res = await getallCategory();
      if (res?.status === "success") {
        setcategoryListData(res?.data);
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    }
  };

  const getSubCategoryName = async () => {
    const data = {
      CategoryNameSlug: SelectedCategory,
    };
    console.log(data, "CategoryNameSlugCategoryNameSlug");
    try {
      const response = await FetchAllSubCategoriesWithCategories(data);
      console.log(
        response,
        "FetchAllSubCategoriesWithCategoriesFetchAllSubCategoriesWithCategories"
      );
      if (response.status === "success") {
        setSubCategoryListData(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const FetchAllShops = async () => {
    try {
      const res = await getAllOurBrands();
      if (res?.status === "success") {
        setAllShopsData(res?.data);
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    }
  };

  const handleCategoryChange = (e, setFieldValue) => {
    const selectedCategory = e.target.value;
    const selectedCategoryData = categoryListData?.find(
      (data) => data.CategoryName === selectedCategory
    );
    setFieldValue("CategoryName", selectedCategory);
    setFieldValue(
      "CategoryNameSlug",
      selectedCategoryData?.CategoryNameSlug || ""
    );
    setSelectedCategory(selectedCategoryData?.CategoryNameSlug || "");
  };

  const handleSubCategoryChange = (e, setFieldValue) => {
    const selectedSubCategory = e.target.value;
    const selectedSubCategoryData = SubCategoryListData?.find(
      (data) => data.SubCategoryName === selectedSubCategory
    );
    setFieldValue("SubCategoryName", selectedSubCategory);
    setFieldValue(
      "SubCategoryNameSlug",
      selectedSubCategoryData?.SubCategoryNameSlug || ""
    );
  };

  useEffect(() => {
    FetchAllCategoryList();
    getSubCategoryName();
    FetchAllShops();
  }, []);

  const validationSchema = Yup.object({
    CategoryName: Yup.string().required("Category Name is required"),
    SubCategoryName: Yup.string().required("Sub Category Name is required"),
    ProductName: Yup.string().required("Product Name is required"),
    ProductNameSlug: Yup.string().required("Product Name Slug is required"),
    ProductDescription: Yup.string().required(
      "Product Description is required"
    ),
    ShopName: Yup.string().required("Shop Name is required"),

    ProductPrice: Yup.number().positive("Must be a positive number"),
    discountPercentage: Yup.number()
      .min(0, "Cannot be less than 0")
      .max(100, "Cannot exceed 100"),
    ProductFeatures: Yup.array()
      .of(Yup.string().required("Feature is required"))
      .min(1, "At least one feature is required"),

    ProductTechnicalSpecification: Yup.object({
      Power: Yup.string().required("Power is required"),
      Voltage: Yup.string().required("Voltage is required"),
      Capacity: Yup.string().required("Capacity is required"),
      Material: Yup.string().required("Material is required"),
    }).required("Technical Specifications are required"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    const formData = new FormData();
    formData.append("CategoryName", values.CategoryName);
    formData.append("CategoryNameSlug", values.CategoryNameSlug);

    formData.append("SubCategoryName", values.SubCategoryName);
    formData.append("SubCategoryNameSlug", values.SubCategoryNameSlug);

    formData.append("ProductName", values.ProductName);
    formData.append("ProductNameSlug", values.ProductNameSlug);

    formData.append("ProductDescription", values.ProductDescription);

    formData.append("ShopName", values.ShopName);
    formData.append("ShopNameSlug", values.ShopNameSlug);

    formData.append("ProductPrice", Number(values.ProductPrice));
    formData.append("DiscountPercentage", values.DiscountPercentage);

    formData.append("ProductFeatures", JSON.stringify(values.ProductFeatures));
    formData.append("ProductTechnicalSpecification", JSON.stringify(values.ProductTechnicalSpecification));

    Array.from(values.ProductImage || []).forEach((file) => {
      formData.append("ProductImage", file);
    });

    console.log("dealsType", values.dealsType); // Ensure this value is correct
    formData.append("dealsType", values.dealsType);

    // Object.entries(values.ProductTechnicalSpecification).forEach(
    //   ([key, value]) => {
    //     formData.append(`${key}`, value);
    //   }
    // );

    for (let [key, value] of formData.entries()) {
      console.log(key, value);
    }

    try {
      const res = await UpdateSingleProducts(productId, formData);
      console.log(res, "UpdateSingleProductsUpdateSingleProducts");
      if (res?.data?.status === "success") {
        toast.success("Product Added Successfully!", { duration: 3000 });
        // resetForm()
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [loading, setLoading] = useState(false);

  const handleStatusChange = async (reviewId, newStatus) => {
    try {
      setLoading(true);

      // Call the update API
      const response = await axios.put(
        `http://localhost:9090/product/reviews/${productId}/${reviewId}`,
        { status: newStatus }
      );

      if (response.data.status === "success") {
        // Update the review status in the UI
        setReviews((prevReviews) =>
          prevReviews.map((review) =>
            review._id === reviewId ? { ...review, status: newStatus } : review
          )
        );
      }
    } catch (error) {
      console.error("Error updating review status:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSingleProductDetails();
  }, []);

  return (
    <div>
      <Toaster position="top-right" reverseOrder={false} />

      <h1>Update Product</h1>
      <p>Updating product with ID: {productId}</p>

      <Formik
        enableReinitialize={true} // This ensures form updates when singleProductData is fetched
        initialValues={{
          CategoryName: singleProductData?.CategoryName || "",
          CategoryNameSlug: singleProductData?.CategoryNameSlug || "",

          SubCategoryName: singleProductData?.SubCategoryName || "",
          SubCategoryNameSlug: singleProductData?.SubCategoryNameSlug || "",

          ProductName: singleProductData?.ProductName || "",
          ProductNameSlug: singleProductData?.ProductNameSlug || "",

          dealsType: singleProductData?.dealsType || "",
          dealsTypeSlug: "",

          ProductDescription: singleProductData?.ProductDescription || "",
          ShopName: singleProductData?.ShopName || "",
          ShopNameSlug: singleProductData?.ShopNameSlug || "",

          ProductPrice: singleProductData?.ProductPrice || "",
          DiscountPercentage: singleProductData?.DiscountPercentage || "",

          ProductFeatures: singleProductData?.ProductFeatures || [""],
          ProductImage: singleProductData?.ProductImage || null,

          ProductTechnicalSpecification: {
            Power:
              singleProductData?.ProductTechnicalSpecification?.Power || "",
            Voltage:
              singleProductData?.ProductTechnicalSpecification?.Voltage || "",
            Capacity:
              singleProductData?.ProductTechnicalSpecification?.Capacity || "",
            Material:
              singleProductData?.ProductTechnicalSpecification?.Material || "",
          },
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6 Form_Field pe-3 p-0">
                    <div className="Form_Field_Heading">
                      Product Name
                      <span>*</span>
                    </div>
                    <Field
                      type="text"
                      name="ProductName"
                      className="Form_Field_Input"
                    />
                    <ErrorMessage
                      name="ProductName"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="col-md-6 Form_Field ps-3">
                    <div className="Form_Field_Heading">
                      Product Name Slug
                      <span>*</span>
                    </div>
                    <Field
                      type="text"
                      name="ProductNameSlug"
                      className="Form_Field_Input"
                      onChange={(e) => {
                        const slug = e.target.value
                          .toLowerCase()
                          .replace(/[^a-z-]/g, "") // Remove invalid characters (numbers, special chars)
                          .replace(/-+/g, "-"); // Replace multiple dashes with single dash
                        setFieldValue("ProductNameSlug", slug);
                      }}
                    />
                    <ErrorMessage
                      name="ProductNameSlug"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-6 Form_Field">
                <div className="Form_Field_Heading">
                  Category Name
                  <span>*</span>
                </div>

                <Field
                  as="select"
                  name="CategoryName"
                  className="Form_Field_Input"
                  onChange={(e) => {
                    handleCategoryChange(e, setFieldValue);
                    setFieldValue("CategoryName", e.target.value); // Update Formik value as well
                  }}
                >
                  <option value="">Select a Category Name</option>

                  {categoryListData?.map((category) => (
                    <option key={category._id} value={category.CategoryName}>
                      {category.CategoryName}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="CategoryName"
                  component="div"
                  className="text-danger"
                />
              </div>

              <div className="col-md-6 Form_Field">
                <div className="Form_Field_Heading">
                  Sub Category Name
                  <span>*</span>
                </div>

                <Field
                  as="select"
                  name="SubCategoryName"
                  className="Form_Field_Input"
                  onChange={(e) => {
                    handleSubCategoryChange(e, setFieldValue);
                    setFieldValue("SubCategoryName", e.target.value); // Update Formik value as well
                  }}
                >
                  <option value="">Select a Sub Category Name</option>

                  {SubCategoryListData?.map((subcategory) => (
                    <option
                      key={subcategory._id}
                      value={subcategory.SubCategoryName}
                    >
                      {subcategory.SubCategoryName}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="CategoryName"
                  component="div"
                  className="text-danger"
                />
              </div>

              <div className="col-md-6 Form_Field">
                <div className="Form_Field_Heading">
                  Deal Name Name
                  <span>*</span>
                </div>

                <label>Deal Name</label>
                <Field
                  as="select"
                  name="dealsType"
                  // onChange={(e) => handleDealChange(e, setFieldValue)}
                >
                  <option value="">Select a Deal</option>
                  {/* {DealsData?.map((item) => {
                    return (
                      <option key={item?._id} value={item?.dealName}>
                        {item?.dealName}
                      </option>
                    );
                  })} */}
                </Field>
              </div>

              <div className="col-md-6 Form_Field">
                <div className="Form_Field_Heading">
                  Shop Name
                  <span>*</span>
                </div>

                <Field
                  as="select"
                  name="ShopName"
                  className="Form_Field_Input"
                  // onChange={(e) => {
                  //   handleShopChange(e, setFieldValue);
                  //   setFieldValue("ShopName", e.target.value); // Update Formik value as well
                  // }}
                >
                  <option value="">Select a Shop</option>

                  {AllShopsData?.map((shop) => (
                    <option key={shop._id} value={shop.OurBrandsName}>
                      {shop.OurBrandsName}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="ShopName"
                  component="div"
                  className="text-danger"
                />
              </div>

              <div className="col-md-6 Form_Field">
                <div className="Form_Field_Heading">
                  Product Images
                  <span>*</span>
                </div>
                <input
                  name="ProductImage"
                  type="file"
                  multiple
                  onChange={(e) =>
                    setFieldValue("ProductImage", e.currentTarget.files)
                  }
                />

                <ErrorMessage
                  name="ProductImage"
                  component="div"
                  className="text-danger"
                />
              </div>

              <div className="col-md-6 Form_Field">
                <div className="Form_Field_Heading">
                  Product Price
                  <span>*</span>
                </div>
                <Field
                  type="number"
                  name="ProductPrice"
                  className="Form_Field_Input"
                />
                <ErrorMessage
                  name="ProductPrice"
                  component="div"
                  className="text-danger"
                />
              </div>

              <div className="col-md-6 Form_Field">
                <div className="Form_Field_Heading">
                  Discount Percentage
                  <span>*</span>
                </div>
                <Field
                  type="number"
                  name="DiscountPercentage"
                  className="Form_Field_Input"
                />
                <ErrorMessage
                  name="DiscountPercentage"
                  component="div"
                  className="text-danger"
                />
              </div>

              <div className="col-md-12 Form_Field">
                <div className="Form_Field_Heading">
                  Product Description
                  <span>*</span>
                </div>

                <Field
                  as="textarea"
                  name="ProductDescription"
                  placeholder="Add a comment..."
                  rows="3"
                  className="Form_Field_Input"
                  required
                />

                <ErrorMessage
                  name="ProductDescription"
                  component="div"
                  className="text-danger"
                />
              </div>

              <div className="col-md-12 Form_Field">
                <div className="Form_Field_Heading">
                  Product Features
                  <span>*</span>
                </div>
                <FieldArray
                  name="ProductFeatures"
                  render={(arrayHelpers) => (
                    <div>
                      {values.ProductFeatures.map((_, index) => (
                        <div key={index}>
                          <Field
                            name={`ProductFeatures[${index}]`}
                            className="Form_Field_Input_Array"
                          />
                          <button
                            type="button"
                            onClick={() => arrayHelpers.remove(index)}
                            className="ms-3 px-1"
                          >
                            Remove
                          </button>
                        </div>
                      ))}
                      <button
                        type="button"
                        onClick={() => arrayHelpers.push("")}
                      >
                        Add Feature
                      </button>
                    </div>
                  )}
                />
                <ErrorMessage
                  name="ProductFeatures"
                  component="div"
                  className="text-danger"
                />
              </div>

              <div className="col-md-12 Form_Field">
                <div className="Form_Field_Heading">
                  Product Specifications
                  <span>*</span>
                </div>
                <div className="row">
                  <div className="col-md-6 m-0 p-0">
                    <div className="Form_Field_Heading">
                      Power (W)
                      <span>*</span>
                    </div>
                    <Field
                      type="number"
                      name="ProductTechnicalSpecification.Power"
                      className="Form_Field_Input"
                    />
                    <ErrorMessage
                      name="ProductTechnicalSpecification.Power"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="col-md-6 p-0 ps-3">
                    <div className="Form_Field_Heading">
                      Voltage (V)
                      <span>*</span>
                    </div>
                    <Field
                      type="number"
                      name="ProductTechnicalSpecification.Voltage"
                      className="Form_Field_Input"
                    />
                    <ErrorMessage
                      name="ProductTechnicalSpecification.Voltage"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="col-md-6 p-0">
                    <div className="Form_Field_Heading">
                      Capacity (Liter)
                      <span>*</span>
                    </div>
                    <Field
                      type="number"
                      name="ProductTechnicalSpecification.Capacity"
                      className="Form_Field_Input"
                    />
                    <ErrorMessage
                      name="ProductTechnicalSpecification.Capacity"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="col-md-6 p-0 ps-3">
                    <div className="Form_Field_Heading">
                      Material
                      <span>*</span>
                    </div>
                    <Field
                      type="text"
                      name="ProductTechnicalSpecification.Material"
                      className="Form_Field_Input"
                    />
                    <ErrorMessage
                      name="ProductTechnicalSpecification.Material"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="Form_Field_Submit_Button">
              <button type="submit">Submit</button>
            </div>
          </Form>
        )}
      </Formik>

      {/* Reviews Table */}

      <div>
        <div className="row py-4">
          <Table responsive="sm">
            <thead>
              <tr>
                <th>S.no</th>
                <th>Product ID</th>
                <th>User Details</th>
                <th>Rating</th>
                <th>Status</th>
                <th>Comment</th>
                <th>createdAt</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(Reviews) && Reviews?.length > 0 ? (
                Reviews.map((ele, index) => (
                  <tr key={ele?._id}>
                    <td>{index + 1}</td>
                    <td>{ele?.productId}</td>

                    <td>
                      <div>
                        {ele?.userDetails?.name
                          ? ele?.userDetails?.name
                          : ele?.userDetails?.mobile}
                      </div>

                      <div>{ele?.userId}</div>
                    </td>
                    <td>{ele?.rating}</td>
                    {/* Dropdown for updating status */}
                    <td>
                      <select
                        value={ele.status}
                        onChange={(e) =>
                          handleStatusChange(ele._id, Number(e.target.value))
                        }
                        disabled={loading}
                      >
                        <option value={0}>Pending</option>
                        <option value={1}>Approved</option>
                      </select>
                    </td>

                    <td>
                      {ele?.comment?.length > 40
                        ? ele?.comment?.slice(0, 60) + "..."
                        : ele?.comment}
                    </td>
                    <td>{ele?.createdAt}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center">
                    Data Not Available
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};
