import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { GrFormAdd } from "react-icons/gr";
import { IoEye } from "react-icons/io5";
import { IoTrashBinOutline } from "react-icons/io5";
import { HiPencilSquare } from "react-icons/hi2";
import {
  createSubCategory,
  deletesingleSubCategory,
  getallSubCategory,
  getSingleSubCategory,
  getSubCategoriesByCategory,
  UpdateSingleSubCategory,
} from "../api/SubCategoryApi";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Button from "react-bootstrap/Button";
import { Toaster, toast } from "react-hot-toast";
import { getallCategory } from "../api/CategoryApi";
import { Base_Url } from "../constant/Url";

const SubCategorySchema = Yup.object().shape({
  CategoryName: Yup.string().required("Category Name is required"),
  CategoryNameSlug: Yup.string().required("Category Name Slug is required"),
  SubCategoryName: Yup.string().required("Sub Category Name is required"),
  SubCategoryNameSlug: Yup.string()
    .matches(
      /^[a-z-]+$/, // No numbers, spaces, or special characters except dashes
      "Slug can only contain lowercase letters and dashes (-), numbers and special characters are not allowed"
    )
    .required("Sub Category Name Slug is required"),
  SubCategoryImage: Yup.mixed()
    .required("Sub Category Image is required")
    .test(
      "fileType",
      "Unsupported file format",
      (value) =>
        value && ["image/jpeg", "image/png", "image/jpg"].includes(value.type)
    )
    .test(
      "fileSize",
      "File size is too large",
      (value) => value && value.size <= 5000000
    ), // 5MB limit
});

export const SubCategory = () => {
  const [subcategoryData, setsubcategoryData] = useState([]);
  const [subsinglecategoryData, setsubsinglecategoryData] = useState([]);

  const FetchAllCategory = async () => {
    try {
      const res = await getallSubCategory();
      if (res?.status === "success") {
        setsubcategoryData(res?.data);
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    }
  };

  const [showViewSubCategoryModal, setShowViewSubCategoryModal] =
    useState(false);

  const handleViewSubCategoryModalClose = () => {
    setShowViewSubCategoryModal(false);
    setsubsinglecategoryData(null); // Clear the data
  };

  const handleViewSubCategoryModalShow = async (ele) => {
    setShowViewSubCategoryModal(true);
    try {
      const res = await getSingleSubCategory(ele?._id);
      console.log(res, "getSingleSubCategory");
      if (res?.status === "success") {
        setsubsinglecategoryData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [showCreateSubCategoryModal, setShowCreateSubCategoryModal] =
    useState(false);

  const handleCreateSubCategoryModalClose = () =>
    setShowCreateSubCategoryModal(false);
  const handleCreateSubCategoryModalShow = () =>
    setShowCreateSubCategoryModal(true);

  const [showUpdateSubCategoryModal, setShowUpdateSubCategoryModal] =
    useState(false);

  const handleUpdateSubCategoryModalClose = () => {
    setShowUpdateSubCategoryModal(false);
    setsubsinglecategoryData(null);
  };

  const handleUpdateSubCategoryModalShow = () =>
    setShowUpdateSubCategoryModal(true);

  const handleUpdateSubCategory = async (ele) => {
    
    try {
      const res = await getSingleSubCategory(ele?._id);
      console.log(res);
      if (res?.status === "success") {
        setsubsinglecategoryData(res?.data);
        handleUpdateSubCategoryModalShow();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteCategory = async (ele) => {
    try {
      const res = await deletesingleSubCategory(ele?._id);
      if (res?.status === "success") {
        toast.success("Subcategory Deleted Successfully!");
        setsubcategoryData(
          subcategoryData.filter((item) => item._id !== ele._id)
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [selectedCategory, setSelectedCategory] = useState("WaterPurifiers");

  useEffect(() => {
    FetchAllCategory();
    FetchAllSubCategory(selectedCategory); // Fetch based on selected category
  }, [selectedCategory]);

  const FetchAllSubCategory = async (categoryName) => {
    try {
      const res = await getSubCategoriesByCategory(categoryName);
      console.log(res);
      if (res?.status === "success") {
        subcategoryData(res?.data);
      } else {
        console.log("No subcategories found for this category");
      }
    } catch (error) {
      console.log("Error fetching subcategory data:", error);
    }
  };

  const handleCategoryClick = (categoryName) => {
    setSelectedCategory(categoryName);
    FetchAllSubCategory(categoryName);
  };

  const [categoryListData, setcategoryListData] = useState([]); // Renamed for clarity

  const FetchAllCategoryList = async () => {
    try {
      const res = await getallCategory();
      if (res?.status === "success") {
        setcategoryListData(res?.data);
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    FetchAllCategoryList();
  }, []);

  const handleSubmitUpdate = async (values, { setSubmitting }) => {
    try {
      const formData = new FormData();
      formData.append("SubCategoryName", values.SubCategoryName);
      formData.append("SubCategoryNameSlug", values.SubCategoryNameSlug);
      formData.append("SubCategoryImage", values.SubCategoryImage);

      for (let [key, value] of formData.entries()) {
        console.log("FormData Entry:", key, value);
      }

      const res = await UpdateSingleSubCategory(
        subsinglecategoryData._id,
        formData
      );

      if (res?.data?.status === "success") {
        toast.success("Sub Category Updated Successfully!", { duration: 3000 });
        handleUpdateSubCategoryModalClose();
        FetchAllSubCategory();
        window.location.reload();
      }

      console.log("API Response:", res);
    } catch (error) {
      console.error("Error in handleSubmitUpdate:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleSubmitCreateSubCategory = async (values, { setSubmitting }) => {
    const formData = new FormData();

    formData.append("CategoryName", values.CategoryName);
    formData.append("CategoryNameSlug", values.CategoryNameSlug);
    formData.append("SubCategoryImage", values.SubCategoryImage);
    formData.append("SubCategoryName", values.SubCategoryName);
    formData.append("SubCategoryNameSlug", values.SubCategoryNameSlug);

    for (let [key, value] of formData.entries()) {
      console.log(key, value);
    }

    try {
      const res = await createSubCategory(formData);
      if (res?.data?.status === "success") {
        toast.success("Sub Category Added Successfully!", { duration: 3000 });
        handleCreateSubCategoryModalClose();
        FetchAllSubCategory();
        window.location.reload();
      }
    } catch (error) {
      console.error("Error creating testimonial:", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <div className="container">
        <Toaster position="top-right" reverseOrder={false} />

        <div className="row">
          <div className="d-flex justify-content-between">
            <div>
              <span style={{ fontWeight: "600", fontSize: "20px" }}>
                Sub-Category Management
              </span>
            </div>

            <div>
              <button
                className="addbutton"
                onClick={handleCreateSubCategoryModalShow}
              >
                Add Sub Category
                <GrFormAdd style={{ fontWeight: "800", fontSize: "25px" }} />
              </button>
            </div>
          </div>
        </div>

        {/* Table - All Subcategory */}

        <div className="row py-4 table_user_data" style={{ overflow: "auto" }}>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>S.no</th>
                <th>Category Name</th>
                <th>Category Name Slug</th>
                <th>Sub Category Name</th>
                <th>Sub Category Name Slug</th>
                <th>Category Image</th>
                <th>actions</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(subcategoryData) && subcategoryData.length > 0 ? (
                subcategoryData?.map((ele, index) => (
                  <tr key={ele?._id}>
                    <td>{index + 1}</td>
                    <td>{ele?.CategoryName}</td>
                    <td>{ele?.CategoryNameSlug}</td>
                    <td>{ele?.SubCategoryName}</td>
                    <td>{ele?.SubCategoryNameSlug}</td>
                    <td>
                      <img
                        src={`${Base_Url}${ele?.SubCategoryImage}`}
                        alt=""
                        style={{ width: "50px", height: "50px" }}
                      />
                    </td>
                    <td>
                      <td>
                        <div className="d-flex">
                          <div
                            onClick={() => handleViewSubCategoryModalShow(ele)}
                            className="action_button_view"
                          >
                            <IoEye />
                          </div>
                          <div
                            className="px-2 action_button_update"
                            onClick={() => handleUpdateSubCategory(ele)}
                          >
                            <HiPencilSquare className="text-success" />
                          </div>
                          <div
                            className="action_button_delete"
                            onClick={() => handleDeleteCategory(ele)}
                          >
                            <IoTrashBinOutline className="text-danger" />
                          </div>
                        </div>
                      </td>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="12" className="text-center">
                    Data Not Available
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>

        {/* View  Subcategory */}

        <div className="row">
          <Modal
            show={showViewSubCategoryModal}
            onHide={handleViewSubCategoryModalClose}
            backdrop="static"
            keyboard={false}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Single Sub category</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div className="modaldata d-flex pb-3">
                  <span>CategoryName</span>
                  {subsinglecategoryData?.CategoryName}
                </div>
                <div className="modaldata d-flex pb-3">
                  <span>SubCategoryName : </span>
                  {subsinglecategoryData?.SubCategoryName}
                </div>
                <div className="modaldata d-flex pb-3">
                  <span>Category Image : </span>
                  <div>
                    <img
                      src={`${Base_Url}${subsinglecategoryData?.SubCategoryImage}`}
                      alt=""
                      style={{ width: "100%", height: "50px" }}
                    />
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>

        {/* Create Subcategory */}

        <div className="row">
          <Modal
            show={showCreateSubCategoryModal}
            onHide={handleCreateSubCategoryModalClose}
            backdrop="static"
            keyboard={false}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Create Sub Category</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Formik
                initialValues={{
                  CategoryName: "",
                  SubCategoryImage: null,
                  SubCategoryName: "",
                }}
                validationSchema={SubCategorySchema}
                onSubmit={handleSubmitCreateSubCategory}
              >
                {({ setFieldValue, values, isSubmitting }) => (
                  <Form>
                    <div>
                      <div>Category Name</div>
                      <Field
                        as="select"
                        name="CategoryName"
                        className="Form_Field"
                        onChange={(e) => {
                          const selectedCategoryName = e.target.value;
                          setFieldValue("CategoryName", selectedCategoryName);

                          // Automatically set CategoryNameSlug based on the selected CategoryName
                          const selectedCategory = categoryListData.find(
                            (category) =>
                              category.CategoryName === selectedCategoryName
                          );
                          if (selectedCategory) {
                            setFieldValue(
                              "CategoryNameSlug",
                              selectedCategory.CategoryNameSlug
                            );
                          } else {
                            setFieldValue("CategoryNameSlug", "");
                          }
                        }}
                      >
                        <option value="">Select Category Name</option>
                        {categoryListData.map((category) => (
                          <option
                            key={category._id}
                            value={category.CategoryName}
                          >
                            {category.CategoryName}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="CategoryName"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    <div className="mt-2">
                      <div>Sub Category Name</div>
                      <Field
                        type="text"
                        name="SubCategoryName"
                        className="Form_Field"
                      />
                      <ErrorMessage
                        name="SubCategoryName"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    <div className="mt-2">
                      <div>Sub Category Name Slug</div>
                      <Field
                        type="text"
                        name="SubCategoryNameSlug"
                        className="Form_Field"
                        onChange={(e) => {
                          const slug = e.target.value
                            .toLowerCase()
                            .replace(/[^a-z-]/g, "") // Remove invalid characters (numbers, special chars)
                            .replace(/-+/g, "-"); // Replace multiple dashes with single dash
                          setFieldValue("SubCategoryNameSlug", slug);
                        }}
                      />
                      <ErrorMessage
                        name="SubCategoryNameSlug"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    <div className="mt-2">
                      <div>Sub Category Image</div>
                      <input
                        type="file"
                        name="SubCategoryImage"
                        className="Form_Field"
                        onChange={(event) => {
                          setFieldValue(
                            "SubCategoryImage",
                            event.currentTarget.files[0]
                          );
                        }}
                      />
                      <ErrorMessage
                        name="SubCategoryImage"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={handleCreateSubCategoryModalClose}
                        disabled={isSubmitting}
                      >
                        Close
                      </Button>
                      <Button
                        type="submit"
                        variant="primary"
                        disabled={isSubmitting}
                      >
                        Create
                      </Button>
                    </Modal.Footer>
                  </Form>
                )}
              </Formik>
            </Modal.Body>
          </Modal>
        </div>

        {/* Update  Subcategory */}

        <div className="row">
          <Modal
            show={showUpdateSubCategoryModal}
            onHide={handleUpdateSubCategoryModalClose}
            backdrop="static"
            keyboard={false}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Update Sub category</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                enableReinitialize
                  initialValues={{
                    SubCategoryImage:
                      subsinglecategoryData?.SubCategoryImage || null,
                    CategoryName: subsinglecategoryData?.CategoryName || "",
                    CategoryNameSlug:
                      subsinglecategoryData?.CategoryNameSlug || "",
                    SubCategoryName:
                      subsinglecategoryData?.SubCategoryName || "",
                    SubCategoryNameSlug:
                      subsinglecategoryData?.SubCategoryNameSlug || "",
                  }}
                  validationSchema={SubCategorySchema}
                  onSubmit={(values, actions) => {
                    console.log("Formik submit handler called"); // Debugging log
                    handleSubmitUpdate(values, actions);
                  }}
                >
                  {({ setFieldValue, isSubmitting, values }) => (
                    <Form>
                      <div>
                        <div>SubCategory Name</div>
                        <Field
                          type="text"
                          name="SubCategoryName"
                          className="Form_Field"
                        />
                        <ErrorMessage
                          name="SubCategoryName"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div>
                        <div>SubCategory Name Slug</div>
                        <Field
                          type="text"
                          name="SubCategoryNameSlug"
                          className="Form_Field"
                          onChange={(e) => {
                            const slug = e.target.value
                              .toLowerCase()
                              .replace(/[^a-z-]/g, "") // Remove invalid characters (numbers, special chars)
                              .replace(/-+/g, "-"); // Replace multiple dashes with single dash
                            setFieldValue("SubCategoryNameSlug", slug);
                          }}
                        />
                        <ErrorMessage
                          name="SubCategoryNameSlug"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="mt-2">
                        <div>SubCategory Image</div>

                        <input
                          type="file"
                          name="SubCategoryImage"
                          className="Form_Field"
                          onChange={(event) => {
                            console.log(
                              "File Selected:",
                              event.currentTarget.files[0]
                            );
                            setFieldValue(
                              "SubCategoryImage",
                              event.currentTarget.files[0]
                            );
                          }}
                        />
                        <ErrorMessage
                          name="SubCategoryImage"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <Modal.Footer>
                        <Button
                          variant="secondary"
                          onClick={handleUpdateSubCategoryModalClose}
                          disabled={isSubmitting}
                        >
                          Close
                        </Button>
                        <Button
                          type="submit"
                          variant="primary"
                          disabled={isSubmitting}
                        >
                          Update
                        </Button>
                      </Modal.Footer>
                    </Form>
                  )}
                </Formik>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
};
