import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { getSingleStaticPages, updatestaticpage } from "../api/StaticPagesApi";
import { CkEditor } from "../components/ckEditor/CkEditor";
import { Toaster, toast } from "react-hot-toast";
import { getDecryptedCookie } from "../utils/Utils";

export const StaticPageUpdate = () => {

  const AdminData = getDecryptedCookie("AdminData");
    const token = AdminData?.token;

  const { staticpageId } = useParams();
  const [initialValues, setInitialValues] = useState({
    staticPageName: "",
    staticPageNameSlugUrl: "",
    staticPageDescription: "",
    Status: "",
  });

  useEffect(() => {
    const getSingleStaticPageDetails = async () => {
      try {
        const response = await getSingleStaticPages(staticpageId);
        if (response?.status === "success" && response.data) {
          setInitialValues({
            staticPageName: response.data.staticPageName || "",
            staticPageNameSlugUrl: response.data.staticPageNameSlugUrl || "", // Fix the key name
            staticPageDescription: response.data.staticPageDescription || "",
            Status: response.data.Status || "",
          });
        }
      } catch (error) {
        console.log("Error fetching static page details:", error);
      }
    };

    if (staticpageId) {
      getSingleStaticPageDetails();
    }
  }, [staticpageId]); // Add dependency here

  const handleSubmit = async (values, { resetForm }) => {
    const formData = new FormData();
    formData.append("staticPageName", values.staticPageName);
    formData.append("staticPageNameSlugUrl", values.staticPageNameSlugUrl);
    formData.append("staticPageDescription", values.staticPageDescription);
    formData.append("Status", values.Status);

    for (let [key, value] of formData.entries()) {
      console.log(key, value);
    }

    try {
      const res = await updatestaticpage(formData, staticpageId,token);

      if (res?.data?.status === "success") {
        toast.success("Static Page Updated Successfully!", { duration: 3000 });
        resetForm();
      }
    } catch (error) {
      console.error("Error updating static page:", error);
    }
  };

  return (
    <div>
      <Toaster />
      <h1>Update Static Page</h1>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div>
              <label htmlFor="staticPageName">Static Page Name</label>
              <Field name="staticPageName" type="text" />
              <ErrorMessage name="staticPageName" component="div" />
            </div>

            <div>
              <label htmlFor="staticPageNameSlugUrl">Slug URL</label>
              <Field name="staticPageNameSlugUrl" type="text" />
              <ErrorMessage name="staticPageNameSlugUrl" component="div" />
            </div>

            <div>
              <label htmlFor="staticPageDescription">Description</label>
              <CkEditor
                value={values?.staticPageDescription}
                onChange={(content) =>
                  setFieldValue("staticPageDescription", content)
                }
              />
            </div>

            <div className="mt-2">
              <div>Status</div>
              <Field as="select" name="Status" className="Form_Field">
                <option value="">Select Status</option>
                <option value="approve">Approve</option>
                <option value="pending">Pending</option>
                <option value="rejected">Rejected</option>
              </Field>
              <ErrorMessage
                name="Status"
                component="div"
                className="text-danger"
              />
            </div>

            <button type="submit">Update Page</button>
          </Form>
        )}
      </Formik>
    </div>
  );
};
