import axios from "axios";
import { Base_Url } from "../constant/Url";

export const createDeals = async (data) => {
  try {
    const res = await fetch(`${Base_Url}/deal/createdeal`, {
      method: "POST",
      body: data,
    });

    if (!res.ok) {
      throw new Error(`Failed to fetch: ${res?.status} ${res?.statusText}`);
    }
    return await res.json();
  } catch (error) {
    return { error: "Failed to fetch data" };
  }
};

export const getAllDeals = async () => {
  try {
    const res = await fetch(`${Base_Url}/deal/getalldeals`, {
      method: "Get",
      headers: {
        "Content-type": "application/json",
      },
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const DeleteDeal = async (id) => {
  try {
    const res = await fetch(`${Base_Url}/deal/deletedeal/${id}`, {
      method: "Delete",
      headers: {
        "Content-type": "application/json",
      },
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const UpdateDealStatusApi = async (formData) => {
  console.log(formData, "formDataformData");
  try {
    const response = await axios.post(`${Base_Url}/deal/editdeal`, formData, {
      headers: {
        "Content-type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error while updating category:", error);
    throw error;
  }
};
