import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Toaster, toast } from "react-hot-toast";
import Table from "react-bootstrap/Table";
import { IoEye } from "react-icons/io5";
import { IoTrashBinOutline } from "react-icons/io5";
import { HiPencilSquare } from "react-icons/hi2";
import { Base_Url } from "../constant/Url.js";
import { CkEditor } from "../components/ckEditor/CkEditor";
import {
  createstaticpage,
  DeletestaticpageApi,
  getAllstaticpage,
} from "../api/StaticPagesApi";
import { useNavigate } from "react-router-dom";
import { getDecryptedCookie } from "../utils/Utils.js";

export const StaticPage = () => {
  const [StaticPagesData, setStaticPagesData] = useState([]);

  const navigate = useNavigate();

  const FetchAllStaticPages = async () => {
    const AdminData = getDecryptedCookie("AdminData");
    const token = AdminData?.token;

    try {
      const res = await getAllstaticpage(token);
      if (res?.status === "success") {
        setStaticPagesData(res?.data);
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    }
  };

  const validationSchema = Yup.object({
    staticPageName: Yup.string().required("static Page Name is required"),
    staticPageNameSlugUrl: Yup.string().required(
      "static PageName Slug Url is required"
    ),
    staticPageDescription: Yup.string().required(
      "static Page Description is required"
    ),
    Status: Yup.string().required("Status is required"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    const formData = new FormData();
    formData.append("staticPageName", values.staticPageName);
    formData.append("staticPageNameSlugUrl", values.staticPageNameSlugUrl);
    formData.append("staticPageDescription", values.staticPageDescription);

    // Log form data for debugging
    for (let [key, value] of formData.entries()) {
      console.log(key, value);
    }

    // Prevent default form submission behavior

    try {
      // Call the API to create a static page
      const res = await createstaticpage(formData);

      // Check for successful response

      if (res?.data?.status === "fail") {
        toast.error(res?.data?.message, { duration: 3000 });
      }

      if (res?.data?.status === "success") {
        toast.success("Static Page Added Successfully!", { duration: 3000 });

        // Refresh the list of static pages
        FetchAllStaticPages();

        // Reset the form
        resetForm();
      } else {
        toast.error(res?.data?.message, { duration: 3000 });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteStaticPage = async (data) => {
    const res = await DeletestaticpageApi(data);
    if (res?.status == "success") {
      toast.success("Static Page Deleted Successfully!", { duration: 3000 });
      FetchAllStaticPages();
    }
    try {
    } catch (error) {
      console.log("Error fetching user data:", error);
    }
  };

  const handleUpdateStaticPage = (data) => {
    navigate(`/staticpage/update/${data?._id}`);
  };

  useEffect(() => {
    FetchAllStaticPages();
  }, []);

  return (
    <div>
      <div className="row">
        <Toaster position="top-right" reverseOrder={false} />

        <Formik
          initialValues={{
            staticPageName: "",
            staticPageNameSlugUrl: "",
            staticPageDescription: "",
            Status: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, errors, touched }) => (
            <Form>
              <div className="row">
                <div className="col-md-5 Form_Field">
                  <div className="Form_Field_Heading">
                    Static PageName
                    <span>*</span>
                  </div>
                  <Field
                    type="text"
                    name="staticPageName"
                    className="Form_Field_Input"
                  />
                  <ErrorMessage
                    name="staticPageName"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="col-md-5 Form_Field">
                  <div className="Form_Field_Heading">
                    Static PageName Slug Url
                    <span>*</span>
                  </div>
                  <Field
                    type="text"
                    name="staticPageNameSlugUrl"
                    className="Form_Field_Input"
                  />
                  <ErrorMessage
                    name="staticPageNameSlugUrl"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="col-md-2 Form_Field">
                  <div className="Form_Field_Heading">
                    Status
                    <span>*</span>
                  </div>
                  <Field as="select" name="Status" className="Form_Field">
                    <option value="">Select Status</option>
                    <option value="approve">Approve</option>
                    <option value="pending">Pending</option>
                    <option value="rejected">Rejected</option>
                  </Field>
                  <ErrorMessage
                    name="Status"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="col-md-12 Form_Field">
                  <div className="Form_Field_Heading">
                    Description
                    <span>*</span>
                  </div>
                  <CkEditor
                    value={values.staticPageDescription}
                    onChange={(content) =>
                      setFieldValue("staticPageDescription", content)
                    }
                  />

                  <ErrorMessage
                    name="staticPageDescription"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="Form_Field_Submit_Button">
                <button type="submit">Submit</button>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <div className="row p-2 table_user_data" style={{ overflow: "auto" }}>
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>S.no</th>
              <th>Page Name</th>
              <th>Description</th>
              <th>Page Name Slug Url</th>
              <th>Status</th>

              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(StaticPagesData) && StaticPagesData.length > 0 ? (
              StaticPagesData?.map((ele, index) => (
                <tr key={ele?._id}>
                  <td>{index + 1}</td>
                  <td>{ele?.staticPageName}</td>
                  <td>{ele?.staticPageNameSlugUrl}</td>

                  {/* <td>
                    {ele?.staticPageDescription?.length > 20
                      ? ele?.staticPageDescription.slice(0, 20) + "..."
                      : ele?.staticPageDescription}
                  </td> */}
                  <td style={{ maxWidth: "100px" }}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: ele?.staticPageDescription,
                      }}
                    />
                  </td>

                  <td>{ele?.Status}</td>

                  <td>
                    <div
                      className="px-2 action_button_update"
                      onClick={() => handleUpdateStaticPage(ele)}
                    >
                      <HiPencilSquare className="text-success" />
                    </div>
                    <div
                      className="action_button_delete"
                      onClick={() => handleDeleteStaticPage(ele?._id)}
                    >
                      <IoTrashBinOutline className="text-danger" />
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="12" className="text-center">
                  Data Not Available
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};
