import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { IoEye } from "react-icons/io5";
import { IoTrashBinOutline } from "react-icons/io5";
import { HiPencilSquare } from "react-icons/hi2";
import { Base_Url } from "../constant/Url";

import { NavLink } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Button from "react-bootstrap/Button";
import { Toaster, toast } from "react-hot-toast";
import { GrFormAdd } from "react-icons/gr";
import {
  createOurBrands,
  DeleteSingleOurBrand,
  getAllOurBrands,
  getSingleOurBrands,
  UpdateSingleOurBrands,
} from "../api/OurBrandsApi";

export const OurBrands = () => {
  const [showUpdateOurBrandsModal, setShowUpdateOurBrandsModal] =
    useState(false);

  const handleUpdateOurBrandsModalClose = () =>
    setShowUpdateOurBrandsModal(false);
  const handleUpdateOurBrandsModalShow = () =>
    setShowUpdateOurBrandsModal(true);

  const [showCreateOurBrandsModal, setShowCreateOurBrandsModal] =
    useState(false);

  const handleCreateOurBrandsModalClose = () =>
    setShowCreateOurBrandsModal(false);
  const handleCreateOurBrandsModalShow = () =>
    setShowCreateOurBrandsModal(true);

  const [showSingleOurBrandsModal, setShowSingleOurBrandsModal] =
    useState(false);

  const handleSingleOurBrandsModalClose = () =>
    setShowSingleOurBrandsModal(false);
  const handleSingleOurBrandsModalShow = () =>
    setShowSingleOurBrandsModal(true);

  const [showDeleteOurBrandsModal, setShowDeleteOurBrandsModal] =
    useState(false);

  const [OurBrandsData, setOurBrandsData] = useState([]);
  const [SingleOurBrandsData, setSingleOurBrandsData] = useState([]);

  const [SelectedOurBrand, setSelectedOurBrand] = useState(null);
  useState(false);

  const handleDeleteOurBrandsModalShow = (ele) => {
    setShowDeleteOurBrandsModal(true);
    setSelectedOurBrand(ele);
  };

  const handleDeleteOurBrandsModalClose = () => {
    setShowDeleteOurBrandsModal(false);
    setSelectedOurBrand(null);
  };

  const handleSubmitDelete = async () => {
    if (SelectedOurBrand) {
      try {
        const res = await DeleteSingleOurBrand(SelectedOurBrand?._id);
        if (res?.status === "success") {
          toast.success("Our Brand Deleted Successfully!");
          handleDeleteOurBrandsModalClose();
          setOurBrandsData(
            OurBrandsData.filter((item) => item._id !== SelectedOurBrand._id)
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const OurBrandsSchema = Yup.object().shape({
    OurBrandsName: Yup.string().required("Our Brands Name is required"),
    OurBrandsNameSlug: Yup.string().required(
      "Our Brands Name Slug is required"
    ),
    OurBrandsImage: Yup.string().required("Our Brands Image URL is required"),
    OurBrandsUrl: Yup.string().required("Our Brands Url is required"),
  });

  const FetchAllOurBrands = async () => {
    try {
      const res = await getAllOurBrands();
      console.log(res);
      if (res?.status === "success") {
        setOurBrandsData(res?.data);
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    FetchAllOurBrands();
  }, []);

  const handleSubmitCreateOurBrands = async (values, { setSubmitting }) => {
    const formData = new FormData();
    formData.append("OurBrandsName", values.OurBrandsName);
    formData.append("OurBrandsNameSlug", values.OurBrandsNameSlug);
    formData.append("OurBrandsUrl", values.OurBrandsUrl);
    formData.append("OurBrandsImage", values.OurBrandsImage);

    try {
      const res = await createOurBrands(formData);
      console.log(res);

      if (res?.data?.status === "success") {
        toast.success(res?.message, { autoClose: "3000" });
        handleCreateOurBrandsModalClose();
        FetchAllOurBrands();
      }
    } catch (error) {
      console.error("Error creating our brands:", error);
      toast.error("Oops! Some error occurred");
    } finally {
      setSubmitting(false);
    }
  };

  const handleSingleOurBrands = async (ele) => {
    try {
      const data = await getSingleOurBrands(ele?._id);
      handleSingleOurBrandsModalShow();
      if (data) {
        setSingleOurBrandsData(data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitUpdate = async (values, { setSubmitting }) => {
    const formData = new FormData();
    formData.append("OurBrandsName", values.OurBrandsName);
    formData.append("OurBrandsNameSlug", values.OurBrandsNameSlug);
    formData.append("OurBrandsUrl", values.OurBrandsUrl);
    if (values.OurBrandsImage) {
      formData.append("OurBrandsImage", values.OurBrandsImage);
    } else {
      formData.append("OurBrandsImage", SingleOurBrandsData.OurBrandsImage);
    }

    try {
      const res = await UpdateSingleOurBrands(
        SingleOurBrandsData._id,
        formData
      );
      if (res?.data?.status === "success") {
        FetchAllOurBrands();
        toast.success(res?.message,{autoClose:"3000"});
        handleUpdateOurBrandsModalClose();
      }
    } catch (error) {
      console.error("Error updating Our Brands:", error);
      toast.error("Oops! Some error occurred");
    } finally {
      setSubmitting(false);
    }
  };

  const handleUpdateOurBrands = async (ele) => {
    try {
      const res = await getSingleOurBrands(ele?._id);
      console.log(res);
      if (res?.status === "success") {
        handleUpdateOurBrandsModalShow();
        setSingleOurBrandsData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container">
      <Toaster position="top-right" reverseOrder={false} />

      <div className="row">
        <div className="d-flex justify-content-between">
          <div>
            <span style={{ fontWeight: "600", fontSize: "20px" }}>
              Our Brands Data Management
            </span>
          </div>
          <div>
            <button
              className="addbutton"
              onClick={handleCreateOurBrandsModalShow}
            >
              Add Our Brands
              <GrFormAdd style={{ fontWeight: "800", fontSize: "25px" }} />
            </button>
          </div>
        </div>
      </div>

      {/* Table - All Our Brands Data */}

      <div className="row py-4 table_user_data" style={{ overflow: "auto" }}>
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>S.no</th>
              <th>Our Brands Name</th>
              <th>Our Brands Name Slug</th>
              <th>Our Brands Url</th>
              <th>Our Brands Image</th>
              <th>actions</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(OurBrandsData) && OurBrandsData.length > 0 ? (
              OurBrandsData?.map((ele, index) => (
                <tr key={ele?._id}>
                  <td>{index + 1}</td>
                  <td>{ele?.OurBrandsName}</td>
                  <td>{ele?.OurBrandsNameSlug}</td>
                  <td>
                    <NavLink to={ele?.OurBrandsUrl}>
                      {ele?.OurBrandsUrl}
                    </NavLink>
                  </td>
                  <td className="d-flex justify-content-center">
                    <div>
                      <img
                        src={`${Base_Url}${ele?.OurBrandsImage}`}
                        alt=""
                        style={{ width: "100%", height: "100px" }}
                      />
                    </div>
                  </td>

                  <td>
                    <td>
                      <div className="d-flex">
                        <div
                          onClick={() => handleSingleOurBrands(ele)}
                          className="action_button_view"
                        >
                          <IoEye />
                        </div>
                        <div
                          className="px-2 action_button_update"
                          onClick={() => handleUpdateOurBrands(ele)}
                        >
                          <HiPencilSquare className="text-success" />
                        </div>
                        <div
                          className="action_button_delete"
                          onClick={() => handleDeleteOurBrandsModalShow(ele)}
                        >
                          <IoTrashBinOutline className="text-danger" />
                        </div>
                      </div>
                    </td>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="12" className="text-center">
                  Data Not Available
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      {/* Get single our Brands */}

      <div className="row">
        <Modal
          show={showSingleOurBrandsModal}
          onHide={handleSingleOurBrandsModalClose}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Single Online Store Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="modaldata d-flex pb-3">
                <span>OurBrandsName : </span>
                <div>{SingleOurBrandsData?.OurBrandsName}</div>
              </div>
              <div className="modaldata d-flex pb-3">
                <span>OurBrandsUrl : </span>

                <NavLink to={SingleOurBrandsData?.OurBrandsUrl}>
                  {SingleOurBrandsData?.OurBrandsUrl}
                </NavLink>
              </div>

              <div className="modaldata d-flex pb-3">
                <span>OurBrandsImage : </span>
                <div>
                  <img
                    src={`${Base_Url}${SingleOurBrandsData?.OurBrandsImage}`}
                    alt=""
                    style={{ width: "100%", height: "50px" }}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>

      {/* Delete Our Brands */}

      <div className="row">
        <Modal
          show={showDeleteOurBrandsModal}
          onHide={handleDeleteOurBrandsModalClose}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Single Our Brand</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h3>Are you sure to delete this Our Brand</h3>
          </Modal.Body>

          <Modal.Footer>
            <Button
              type="submit"
              variant="primary"
              onClick={() => handleSubmitDelete()}
            >
              Yes
            </Button>
            <Button
              variant="secondary"
              onClick={handleDeleteOurBrandsModalClose}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      {/* Create Our Brands */}

      <div className="row">
        <Modal
          show={showCreateOurBrandsModal}
          onHide={handleCreateOurBrandsModalClose}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Create Online Store Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                OurBrandsName: "",
                OurBrandsNameSlug: "",
                OurBrandsImage: null,
                OurBrandsUrl: "",
              }}
              validationSchema={OurBrandsSchema}
              onSubmit={handleSubmitCreateOurBrands}
            >
              {({ setFieldValue, values, isSubmitting }) => (
                <Form>
                  <div>
                    <div>Our Brands Name</div>
                    <Field
                      type="text"
                      name="OurBrandsName"
                      className="Form_Field"
                    />
                    <ErrorMessage
                      name="OurBrandsName"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div>
                    <div>Our Brands Name Slug</div>
                    <Field
                      type="text"
                      name="OurBrandsNameSlug"
                      className="Form_Field"
                    />
                    <ErrorMessage
                      name="OurBrandsNameSlug"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div>
                    <div>Our Brands Url</div>
                    <Field
                      type="text"
                      name="OurBrandsUrl"
                      className="Form_Field"
                    />
                    <ErrorMessage
                      name="OurBrandsUrl"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="mt-2">
                    <div>Our Brands Image</div>
                    <input
                      type="file"
                      name="OurBrandsImage"
                      className="Form_Field"
                      onChange={(event) => {
                        setFieldValue(
                          "OurBrandsImage",
                          event.currentTarget.files[0]
                        );
                      }}
                    />
                    <ErrorMessage
                      name="OurBrandsImage"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={handleCreateOurBrandsModalClose}
                      disabled={isSubmitting}
                    >
                      Close
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={isSubmitting}
                    >
                      Create
                    </Button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>
      </div>

      {/* Update Our Brands */}

      <div className="row">
        <Modal
          show={showUpdateOurBrandsModal}
          onHide={handleUpdateOurBrandsModalClose}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Our Brands Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Formik
                initialValues={{
                  OurBrandsName: SingleOurBrandsData.OurBrandsName || "",
                  OurBrandsNameSlug:
                    SingleOurBrandsData.OurBrandsNameSlug || "",
                  OurBrandsImage: SingleOurBrandsData.OurBrandsImage || null,
                  OurBrandsUrl: SingleOurBrandsData.OurBrandsUrl || "",
                }}
                validationSchema={OurBrandsSchema}
                onSubmit={handleSubmitUpdate}
              >
                {({ setFieldValue, values, isSubmitting }) => (
                  <Form>
                    <div>
                      <div>Our Brands Name</div>
                      <Field
                        type="text"
                        name="OurBrandsName"
                        className="Form_Field"
                      />
                      <ErrorMessage
                        name="OurBrandsName"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    <div>
                      <div>Our Brands Name Slug</div>
                      <Field
                        type="text"
                        name="OurBrandsNameSlug"
                        className="Form_Field"
                      />
                      <ErrorMessage
                        name="OurBrandsNameSlug"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    <div>
                      <div>Our Brands Url</div>
                      <Field
                        type="text"
                        name="OurBrandsUrl"
                        className="Form_Field"
                      />
                      <ErrorMessage
                        name="OurBrandsUrl"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    <div className="mt-2">
                      <div>Our Brands Image</div>
                      <input
                        type="file"
                        name="OurBrandsImage"
                        className="Form_Field"
                        onChange={(event) => {
                          setFieldValue(
                            "OurBrandsImage",
                            event.currentTarget.files[0]
                          );
                        }}
                      />
                      <ErrorMessage
                        name="OurBrandsImage"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={handleUpdateOurBrandsModalClose}
                        disabled={isSubmitting}
                      >
                        Close
                      </Button>
                      <Button
                        type="submit"
                        variant="primary"
                        disabled={isSubmitting}
                      >
                        Update
                      </Button>
                    </Modal.Footer>
                  </Form>
                )}
              </Formik>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};
