import axios from "axios";
import { Base_Url } from "../constant/Url";

export const createProduct = async (formData) => {
  try {
    const response = await axios.post(
      `${Base_Url}/product/createproduct`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error creating Product", error);
    throw error;
  }
};

export const getallProducts = async () => {
  try {
    const res = await fetch(`${Base_Url}/product/getallproduct`, {
      method: "Get",
      headers: {
        "Content-type": "application/json",
      },
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const getSingleProducts = async (id) => {
  try {
    const res = await fetch(`${Base_Url}/product/getsingleProduct/${id}`, {
      method: "Get",
      headers: {
        "Content-type": "application/json",
      },
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const DeleteSingleProduct = async (id) => {
  try {
    const res = await fetch(`${Base_Url}/product/deletesingleproduct/${id}`, {
      method: "Delete",
      headers: {
        "Content-type": "application/json",
      },
    });
    console.log(res);
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const UpdateSingleProducts = async (product_id, formData) => {
  try {
    // Convert ProductTechnicalSpecification from string to object if needed
    if (typeof formData.ProductTechnicalSpecification === "string") {
      try {
        formData.ProductTechnicalSpecification = JSON.parse(formData.ProductTechnicalSpecification);
      } catch (error) {
        console.error("Error parsing ProductTechnicalSpecification:", error);
      }
    }

    // Ensure ProductFeatures is an array and not a stringified array
    if (Array.isArray(formData.ProductFeatures) && typeof formData.ProductFeatures[0] === "string") {
      try {
        formData.ProductFeatures = JSON.parse(formData.ProductFeatures[0]);
      } catch (error) {
        console.error("Error parsing ProductFeatures:", error);
      }
    }

    console.log("Final FormData before sending:", formData);

    const response = await axios.put(
      `${Base_Url}/product/updatesingleproduct/${product_id}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error updating product:", error);
    throw error;
  }
};


// export const GetProductReviewsAPI = async (productId, mobile) => {
//   try {
//     const response = await axios.post(
//       `http://localhost:9090/product/reviews/${productId}`,
//       mobile,
//       {
//         headers: {
//           "Content-Type": "application/json",
//         },
//       }
//     );
//     return response.data;
//   } catch (error) {
//     return { error: "Failed to fetch data" };
//   }
// };

export const GetProductReviewsAPI = async (token) => {
  try {
    const res = await fetch(`http://localhost:9090/product/getallreviews`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Ensure JSON format
        Authorization: `Bearer ${token}`, // Include token
      },
    });

    if (!res.ok) {
      throw new Error(`Failed to fetch: ${res?.status} ${res?.statusText}`);
    }
    return await res.json();
  } catch (error) {
    return { error: "Failed to fetch data" };
  }
};

export const updateProductReviewsStatusAPI = async (
  productId,
  data,
  reviewId
) => {
  console.log(productId, reviewId, data);
  try {
    const res = await fetch(
      `http://localhost:9090/product/reviews/${productId}/${reviewId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json", // Ensure JSON format
        },
        body: JSON.stringify(data), // Convert data to JSON string
      }
    );

    if (!res.ok) {
      throw new Error(`Failed to fetch: ${res?.status} ${res?.statusText}`);
    }
    return await res.json();
  } catch (error) {
    return { error: "Failed to fetch data" };
  }
};
