import React, { useState, useEffect, useContext } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import toast, { Toaster } from "react-hot-toast";
import { setEncryptedCookie } from "../utils/Utils";
import { Link, useNavigate } from "react-router-dom";
import "../styles/Login.css";
import { loginWithPhoneNumberApi, otpVerifiedApi } from "../api/LoginAPI";

const validationSchema = Yup.object({
  mobile: Yup.string()
    .required("Mobile number is required")
    .matches(/^[0-9]{10}$/, "Invalid mobile number"),
  otp: Yup.string().when("isOtpSent", {
    is: true,
    then: Yup.string()
      .required("OTP is required")
      .matches(/^[0-9]{6}$/, "OTP must be 6 digits"),
  }),
});

export const Login = () => {
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [isSendingOtp, setIsSendingOtp] = useState(false); // Local state to manage OTP
  const [resendTimer, setResendTimer] = useState(0); // Timer state for "Resend OTP" button
  const navigate = useNavigate();

  // Function to send OTP
  const handleSendOTP = async (values) => {
    setIsSendingOtp(true); // Set sending state to true
    try {
      setMobileNumber(values.mobile); // Save the mobile number for later OTP verification
      const res = await loginWithPhoneNumberApi({ mobile: values.mobile });
      if (res?.status === "success") {
        setIsOtpSent(true);
        setIsSendingOtp(true);
        setResendTimer(30);
        toast.success("OTP Sent Successfully", { autoClose: 4000 });
      } else {
        toast.error("Failed to send OTP", { autoClose: 4000 });
      }
    } catch (error) {
      toast.error("Something went wrong", { autoClose: 4000 });
    } finally {
      setIsSendingOtp(false); // Reset sending state after the process is complete
    }
  };

  // Function to verify OTP
  const handleVerifyOtp = async (values) => {
    try {
      const res = await otpVerifiedApi({
        mobile: mobileNumber,
        otp: values?.otp,
      });


      if (res?.status === "success") {
        toast.success("Login Successfully", { autoClose: 3000 });
        // syncGuestCartToAPI()
        setEncryptedCookie("AdminData", {
          mobile: res?.data?.mobile,
          token: res?.token,
          status: res?.status,
          userId: res?.data?._id,
          role: res?.data?.role,
        });
        window.location.href = "/";
      } else {
        toast.error("Incorrect OTP!", { autoClose: 4000 });
      }
    } catch (error) {
      toast.error("Error verifying OTP", { autoClose: 4000 });
    }
  };

  // Function to handle Resend OTP and start the timer
  const handleResendOtp = (values, setFieldValue) => {
    // Reset OTP field
    setFieldValue("otp", "");
    handleSendOTP(values); // Resend OTP with current mobile number

    // Start a 15-second countdown
    setResendTimer(30);
  };

  // Set up the timer countdown using useEffect
  useEffect(() => {
    let timer;
    if (resendTimer > 0) {
      timer = setInterval(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);
    } else if (resendTimer === 0) {
      clearInterval(timer);
    }

    return () => clearInterval(timer); // Cleanup interval when the component is unmounted
  }, [resendTimer]);

  return (
    <>
      <Toaster />
      {/* login section start */}
      <div className="login_page_section">
        {/* container start */}
        <div className="container">
          {/* row start */}
          <div className="row justify-content-center">
            {/* col start */}
            <div className="col-lg-5 col-md-12 col-sm-12 col-12">
              {/* login form main div start */}
              <div className="login_form_main_div">
                {/* title start */}
                <div className="login_title_div">
                  {/* login img start */}
                  <div className="login_img_box">
                    {/* <img src={loginimg} alt="login" className="img-fluid" /> */}
                  </div>
                  {/* login img end */}
                  <h1 className="login_form_title">User Login</h1>
                </div>
                {/*  title end */}
                <Formik
                  initialValues={{ mobile: "", otp: "" }}
                  validationSchema={validationSchema}
                  onSubmit={isOtpSent ? handleVerifyOtp : handleSendOTP}
                >
                  {({ isSubmitting, values, setFieldValue }) => (
                    <Form>
                      <div className="col-lg-12">
                        <label>
                          Mobile <span>*</span>
                        </label>
                        <Field
                          type="text"
                          name="mobile"
                          className="form-control"
                          placeholder="Mobile Number"
                        />
                        <ErrorMessage
                          name="mobile"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      {isOtpSent && (
                        <div className="col-md-12 mt-2">
                          <label>
                            {" "}
                            OTP <span>*</span>
                          </label>
                          <Field
                            type="text"
                            name="otp"
                            className="form-control"
                            placeholder="Enter OTP"
                          />
                          <ErrorMessage
                            name="otp"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      )}

                      <div className="login_term_txt">
                        <p>
                          {" "}
                          By signing up, you agree to our{" "}
                          <Link to="/termsconditions">
                            {" "}
                            terms & conditions{" "}
                          </Link>{" "}
                          and <Link to="/privacypolicy"> privacy policy </Link>
                        </p>
                      </div>

                      <div className="login_btn mt-3 d-flex justify-content-center gap-2">
                        <button
                          type="submit"
                          disabled={
                            isSubmitting || (!isOtpSent && !values.mobile)
                          }
                          className="common_button"
                        >
                          {isOtpSent ? "Verify OTP" : "Send OTP"}
                        </button>

                        {isOtpSent && (
                          <button
                            type="button"
                            onClick={() =>
                              handleResendOtp(values, setFieldValue)
                            }
                            disabled={resendTimer > 0} // Disable only when resendTimer is greater than 0
                            className={`common_button ${
                              resendTimer > 0 ? "disabled" : "enabled"
                            }`}
                          >
                            {resendTimer > 0
                              ? `Resend OTP (${resendTimer}s)`
                              : "Resend OTP"}
                          </button>
                        )}
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
              {/* login form main div end */}
            </div>
            {/* col end */}
          </div>
          {/* row end */}
        </div>
        {/* container end */}
      </div>
      {/* login section enb */}
    </>
  );
};
