import React, { useEffect, useState } from "react";
import {
  createBlogs,
  DeleteBlogApi,
  DeleteSingleBlogApi,
  getAllBlogs,
} from "../api/BlogsApi";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Toaster, toast } from "react-hot-toast";
import Table from "react-bootstrap/Table";
import { IoEye } from "react-icons/io5";
import { IoTrashBinOutline } from "react-icons/io5";
import { HiPencilSquare } from "react-icons/hi2";
import { Base_Url } from "../constant/Url.js";
import { useNavigate } from "react-router-dom";
import { CkEditor } from "../components/ckEditor/CkEditor.js";

export const Blogs = () => {
  const [BlogsData, setBlogsData] = useState([]);

  const navigate = useNavigate();

  const FetchAllBlogs = async () => {
    try {
      const res = await getAllBlogs();
      if (res?.status === "success") {
        setBlogsData(res?.data);
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    }
  };

  const handleDeleteBlogs = async (data) => {
    try {
      console.log(data?._id, "DeleteSingleBlogs");
      const res = await DeleteBlogApi(data?._id);
      if (res?.status === "success") {
        toast.success("Blogs Delete Successfully!", { duration: 3000 });
        FetchAllBlogs();
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    }
  };

  const validationSchema = Yup.object({
    BlogsAuthor: Yup.string().required("Blogs Author Name is required"),
    BlogsImage: Yup.string().required("Blogs Image is required"),
    BlogsTitle: Yup.string().required("Blogs Title Name is required"),
    BlogsDescription: Yup.string().required("Blogs Descriptionis required"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    const formData = new FormData();
    formData.append("BlogsAuthor", values.BlogsAuthor);
    formData.append("BlogsImage", values.BlogsImage);

    formData.append("BlogsTitle", values.BlogsTitle);
    formData.append("BlogsDescription", values.BlogsDescription);

    for (let [key, value] of formData.entries()) {
      console.log(key, value);
    }

    try {
      const res = await createBlogs(formData);
      if (res?.data?.status === "success") {
        toast.success("Blogs Added Successfully!", { duration: 3000 });
        FetchAllBlogs();
        resetForm();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateBlogs = (data) => {
    navigate(`/blogs/update/${data?._id}`);
  };

  useEffect(() => {
    FetchAllBlogs();
  }, []);

  useEffect(() => {
    const mydata = localStorage.getItem("productDescription");
    console.log(mydata);
  }, []);

  return (
    <div>
      <div>
        <div className="row">
          <Toaster position="top-right" reverseOrder={false} />

          <Formik
            initialValues={{
              BlogsAuthor: "",
              BlogsImage: "",

              BlogsTitle: "",
              BlogsDescription: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, errors, touched }) => (
              <Form>
                <div className="row">
                  <div className="col-md-6 Form_Field">
                    <div className="Form_Field_Heading">
                      Blogs Author Name
                      <span>*</span>
                    </div>
                    <Field
                      type="text"
                      name="BlogsAuthor"
                      className="Form_Field_Input"
                    />
                    <ErrorMessage
                      name="BlogsAuthor"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="col-md-6 Form_Field">
                    <div className="Form_Field_Heading">
                      Blogs Title
                      <span>*</span>
                    </div>
                    <Field
                      type="text"
                      name="BlogsTitle"
                      className="Form_Field_Input"
                    />
                    <ErrorMessage
                      name="BlogsTitle"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="col-md-6 Form_Field">
                    <div className="Form_Field_Heading">
                      Blogs Image
                      <span>*</span>
                    </div>

                    <input
                      type="file"
                      name="BlogsImage"
                      onChange={(event) => {
                        setFieldValue(
                          "BlogsImage",
                          event.currentTarget.files[0]
                        );
                      }}
                    />
                    <ErrorMessage
                      name="BlogsImage"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="col-md-6 Form_Field">
                    {/* <div className="Form_Field_Heading">
                      Blogs Description
                      <CkEditor />
                      <span>*</span>
                    </div> */}

                    {/* <Field
                      as="textarea"
                      name="BlogsDescription"
                      placeholder="Add a comment..."
                      rows="3"
                      className="Form_Field_Input"
                      required
                    /> */}

                    <CkEditor
                      value={values.BlogsDescription}
                      onChange={(content) =>
                        setFieldValue("BlogsDescription", content)
                      }
                    />

                    <ErrorMessage
                      name="BlogsDescription"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>
                <div className="Form_Field_Submit_Button">
                  <button type="submit">Submit</button>
                </div>
              </Form>
            )}
          </Formik>
        </div>

        <div className="row p-2 table_user_data" style={{ overflow: "auto" }}>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>S.no</th>
                <th>Blogs Author</th>
                <th>Blogs Image</th>
                <th>Blogs Title</th>
                <th>Blogs Description</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(BlogsData) && BlogsData.length > 0 ? (
                BlogsData?.map((ele, index) => (
                  <tr key={ele?._id}>
                    <td>{index + 1}</td>
                    <td>{ele?.BlogsAuthor}</td>
                    <td>
                      <img
                        src={`${Base_Url}${ele?.BlogsImage}`}
                        alt={ele?.BlogsImage}
                        style={{ width: "50px", height: "50px" }}
                      />
                    </td>
                    <td>{ele?.BlogsTitle}</td>
                    <td>
                      {ele?.BlogsDescription?.length > 20
                        ? ele?.BlogsDescription.slice(0, 20) + "..."
                        : ele?.BlogsDescription}
                    </td>

                    <td>
                      <td>
                        <div className="d-flex">
                          <div className="action_button_view">
                            <IoEye />
                          </div>
                          <div
                            className="px-2 action_button_update"
                            onClick={() => handleUpdateBlogs(ele)}
                          >
                            <HiPencilSquare className="text-success" />
                          </div>
                          <div
                            className="action_button_delete"
                            onClick={() => handleDeleteBlogs(ele)}
                          >
                            <IoTrashBinOutline className="text-danger" />
                          </div>
                        </div>
                      </td>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="12" className="text-center">
                    Data Not Available
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};
